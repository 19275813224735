import { IFooterSection } from 'models/layouts/footer.model';
import { EFooterSection } from 'enums/footerSection.enum';
import { EFooterSupplierLink } from 'enums/footerSupplierLink.enum';
import { ECommonFile } from 'shared/enums/commonFile.enum';

export const footerSections: Array<IFooterSection> = [
  {
    title: 'О компании',
    id: EFooterSection.AboutCompany,
    links: [
      { to: '/about/', text: 'О Максмарт' },
      { to: 'https://help.maksmart.ru/article/54285', text: 'Документы' },
      { to: '/contact/', text: 'Контакты' },
      { to: '/news/', text: 'Новости' },
    ],
  },
  {
    title: 'Покупателям',
    id: EFooterSection.ForClients,
    links: [
      { to: '/pokupatelyam/', text: 'Стать покупателем' },
      { to: '/', text: 'Каталог товаров', disabled: true },
      { to: '/', text: 'Интеграции', disabled: true },
      { to: '/', text: 'База знаний', disabled: true },
    ],
  },
  {
    title: 'Поставщикам',
    id: EFooterSection.ForSuppliers,
    links: [
      { to: '/postavschikam/', text: 'Стать поставщиком' },
      { to: '/', text: 'Условия размещения', disabled: true },
      { to: ECommonFile.GuideWorkingWithContent, text: 'Требования к каталогу', file: true },
      { to: '/', text: 'Интеграции', disabled: true },
      { to: '', text: 'База знаний', id: EFooterSupplierLink.SupplierKnowledgeBase, disabled: true },
    ],
  },
];
